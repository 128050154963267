<template>
  <q-header elevated>
    <q-toolbar>
      <q-toolbar-title>
        <transition duration="400" enter-active-class="animate__animated animate__flipInX"
          leave-active-class="animate__animated animate__flipOutX">
          <q-btn v-show="$route.path.split('/').length > 2" dense flat icon="arrow_back" round transition-hide="jump-up"
            transition-show="jump-down" @click="$router.back()" />
        </transition>

        <q-btn dense flat icon="menu" round @click="onMenuClick">
          <q-tooltip>Меню</q-tooltip>
        </q-btn>
        РСВ-маркет
      </q-toolbar-title>

      <q-space />
      <div class="q-gutter-sm row items-center no-wrap">
        <q-btn color="white" dense flat icon="vertical_align_top" round @click="scrollToTop">
          <q-tooltip>
            Наверх
          </q-tooltip>
        </q-btn>
        <q-btn v-if="$q.screen.gt.sm" :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'" color="white"
          dense flat round @click="$q.fullscreen.toggle()">
          <q-tooltip>
            Полный экран
          </q-tooltip>
        </q-btn>
        <q-btn icon="dark_mode" round size="xs" @click="toggleDarkMode">
          <q-tooltip>
            Ночной режим
          </q-tooltip>
        </q-btn>
        <q-btn flat round>
          <q-avatar color=red icon="person" size="26px" />

          <q-tooltip>
            Ваш аккаунт
          </q-tooltip>

          <q-menu>
            <q-card>
              <q-card-section>
                <div class="text-center" style="width: 200px;">
                  {{ rsv_user_id }}
                  <q-separator class="q-ma-md" />
                  <q-btn v-close-popup color="primary" icon="logout" label="Выход" size="sm" @click="logout" />
                </div>

              </q-card-section>
            </q-card>
          </q-menu>
        </q-btn>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script>
import { userStorage } from '@/lib/userStorage';
import { Dark } from 'quasar';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Header',

  props: {
    onMenuClick: {
      type: Function,
      required: true,
    },
  },

  components: {},

  setup() {
    const store = useStore();
    const leftDrawerOpen = ref(false);

    Dark.set(userStorage.darkMode);

    return {
      rsv_user_id: computed(() => store.state.user.rsv_user_id),
      logout: () => store.dispatch('logout'),
      leftDrawerOpen,
      toggleDarkMode() {
        Dark.toggle();
        userStorage.darkMode = Dark.isActive;
      },
      scrollToTop: () => window.scrollTo({
        top: 0,
        behavior: 'smooth',
      }),
    };
  },
});
</script>
