<template>
  <q-list>
    <div v-if="isAdmin">
<!--      <q-item active-class="q-item-active" to="/opportunities">-->
<!--        <q-item-section avatar>-->
<!--          <q-icon name="account_balance_wallet"/>-->
<!--        </q-item-section>-->
<!--        <q-item-section>-->
<!--          <q-item-label>Как заработать</q-item-label>-->
<!--          <q-item-label caption class="text-grey">Информация для пользователей</q-item-label>-->
<!--        </q-item-section>-->
<!--      </q-item>-->

<!--      <q-item active-class="q-item-active" to="/campaigns">-->
<!--        <q-item-section avatar>-->
<!--          <q-icon name="card_giftcard"/>-->
<!--        </q-item-section>-->
<!--        <q-item-section>-->
<!--          <q-item-label>Начисления</q-item-label>-->
<!--          <q-item-label caption class="text-grey">Управление кодами</q-item-label>-->
<!--        </q-item-section>-->
<!--      </q-item>-->

      <q-item active-class="q-item-active" to="/organizations">
        <q-item-section avatar>
          <q-icon name="corporate_fare"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Партнёры</q-item-label>
          <q-item-label caption class="text-grey">РСВ-маркет</q-item-label>
        </q-item-section>
      </q-item>

<!--      <q-item active-class="q-item-active" to="/news">-->
<!--        <q-item-section avatar>-->
<!--          <q-icon name="newspaper"/>-->
<!--        </q-item-section>-->
<!--        <q-item-section>-->
<!--          <q-item-label>Новости</q-item-label>-->
<!--        </q-item-section>-->
<!--      </q-item>-->

      <q-item active-class="q-item-active" to="/users">
        <q-item-section avatar>
          <q-icon name="people"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Пользователи</q-item-label>
          <q-item-label caption class="text-grey"></q-item-label>
        </q-item-section>
      </q-item>

      <!--      <q-item active-class="q-item-active" to="/Charts">-->
      <!--        <q-item-section avatar>-->
      <!--          <q-icon name="insert_chart"/>-->
      <!--        </q-item-section>-->
      <!--        <q-item-section>-->
      <!--          <q-item-label>Статистика</q-item-label>-->
      <!--        </q-item-section>-->
      <!--      </q-item>-->
      <q-item active-class="q-item-active" to="/coupons">
        <q-item-section avatar>
          <q-icon name="badge"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Купоны</q-item-label>
          <q-item-label caption class="text-grey">Информация о купонах</q-item-label>
        </q-item-section>
      </q-item>

<!--      <q-item active-class="q-item-active" to="/feedback">-->
<!--        <q-item-section avatar>-->
<!--          <q-icon name="rate_review"/>-->
<!--        </q-item-section>-->
<!--        <q-item-section>-->
<!--          <q-item-label>Отзывы</q-item-label>-->
<!--          <q-item-label caption class="text-grey"></q-item-label>-->
<!--        </q-item-section>-->
<!--      </q-item>-->

<!--      <q-item active-class="q-item-active" to="/banners">-->
<!--        <q-item-section avatar>-->
<!--          <q-icon name="ad_units"/>-->
<!--        </q-item-section>-->
<!--        <q-item-section>-->
<!--          <q-item-label>Баннеры</q-item-label>-->
<!--          <q-item-label caption class="text-grey"></q-item-label>-->
<!--        </q-item-section>-->
<!--      </q-item>-->

      <q-item active-class="q-item-active" to="/offers/positions">
        <q-item-section avatar>
          <q-icon name="picture_in_picture"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Расположение поощрений</q-item-label>
          <q-item-label caption class="text-grey"></q-item-label>
        </q-item-section>
      </q-item>

      <q-item active-class="q-item-active" to="/categories">
        <q-item-section avatar>
          <q-icon name="category"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Категории</q-item-label>
          <q-item-label caption class="text-grey"></q-item-label>
        </q-item-section>
      </q-item>
    </div>

    <q-separator v-if="isAdmin && isEmployee" class="q-ma-md"/>

    <div v-if="isEmployee">
      <q-item active-class="q-item-active" to="/employee">
        <q-item-section avatar>
          <q-icon name="badge"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Сотрудникам партнеров</q-item-label>
          <q-item-label caption class="text-grey">Управление предложениями, организациями,
            сотрудниками
          </q-item-label>
        </q-item-section>
      </q-item>
    </div>

    <div v-if="isEmployee">
      <q-item active-class="q-item-active" to="/coupons">
        <q-item-section avatar>
          <q-icon name="badge"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Купоны</q-item-label>
          <q-item-label caption class="text-grey">Информация о купонах</q-item-label>
        </q-item-section>
      </q-item>
    </div>

  </q-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'isAdmin',
      'isEmployee',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/quasar.variables.scss';

.q-item-active {
  color: lighten($primary, 20%);
  background: darken($primary, 5%);
  padding-left: 20px;
}
</style>
